import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getTransactions: { args: ["payload"], meta: { async: true } },
    getExportedTransactions: { args: ["payload"], meta: { async: true } },
    getShopsTransactions: { args: ["payload"], meta: { async: true } },
    getAllTransactions: { args: [], meta: { async: true } },
    getTransactionWithId: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
    
  },
  {
    prefix: "@app/transactions/",
  }
);
export const ActionTypes = Types;
export default Creators;
