// import { toAbsoluteUrl } from "src/_metronic/_helpers";

export const locales = {
  "ar": {
    "code": "ar",
    "name": "العربية",
    "direction": "RTL",
    // "flag": toAbsoluteUrl("/media/svg/flags/158-egypt.svg"),
    "culture": "ar-SA",
    flag:"eg",
    isRTL:true,
    default: true
  },
  // "en": {
  //   "code": "en",
  //   "name": "English",
  //   "direction": "LTR",
  //   // "flag": toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  //   "culture": "en-US",
  //   flag:"us",
  //   default: false
  // }
}