import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllGovernorate:{ args: ["payload"], meta: { async: true } },
    getRegions:{ args: ["payload"], meta: { async: true } },
    createGovernorate:{ args: ["payload"], meta: { async: true } },
    editGovernorate:{ args: ["payload"], meta: { async: true } },
    deleteGovernorate:{ args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Governorates/",
  }
);
export const ActionTypes = Types;
export default Creators;
