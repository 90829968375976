import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "advertisements";

const getAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ADVERTISEMENT,
});

const addAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_ADVERTISEMENT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_ADVERTISEMENT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteAdvertisementLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ADVERTISEMENT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAdvertisementLogic,
  addAdvertisementLogic,
  editAdvertisementLogic,
  deleteAdvertisementLogic,
];
