export default function (/**@type {ApisauceInstance} */ api) {
  const getAllGovernorate = () => api.get(`Governorate`);
  const getAllCities = (paging) => api.get(`City`,paging);
  const createCity = (payload) => api.post(`City`, payload);
  const editCity = ({ id, payload }) => api.put(`City/${id}`, payload);
  const deleteCity = (id) => api.delete(`City/${id}`);

  return {
    Cities: {
      getAllGovernorate,
      getAllCities,
      createCity,
      editCity,
      deleteCity,
    },
  };
}
