import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "transactions";


const getTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS,
});
const getShopsTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SHOPS_TRANSACTIONS,
});
const getExportedTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_EXPORTED_TRANSACTIONS,
});
const getAllTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_TRANSACTIONS,
});
const getTransactionWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTION_WITH_ID,
});




export default [
  getTransactionsLogic,
  getAllTransactionsLogic,
  getShopsTransactionsLogic,
  getTransactionWithIdLogic,
  getExportedTransactionsLogic
];
