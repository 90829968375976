import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    // categories
    getAllCategories: { args: ["payload"], meta: { async: true } },
    getCategoryWithId: { args: ["payload"], meta: { async: true } },
    addCategory: { args: ["payload"], meta: { async: true } },
    editCategory: { args: ["payload"], meta: { async: true } },
    deleteCategory: { args: ["payload"], meta: { async: true } },
    // products
    addProduct: { args: ["payload"], meta: { async: true } },
    editProduct: { args: ["payload"], meta: { async: true } },
    deleteProduct: { args: ["payload"], meta: { async: true } },
    //Featuerd Pack
    addFeatuerdPack: { args: ["payload"], meta: { async: true } },
    changeTarget: { args: ["payload"], meta: { async: true } },
    savePackPosition: { args: ["payload"], meta: { async: false } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Categories/",
  }
);
export const ActionTypes = Types;
export default Creators;
