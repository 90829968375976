import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("packTypes", { metadata: undefined }, []),
  ...defaultState(
    "packType",
    { metadata: undefined },
    {
      products: [],
    }
  ),
};


const packTypeEditedSuccess = (state, draft, payload) => {
  const packTypes = state.packTypes || [];
  var packTypeIndex = packTypes.findIndex((u) => u.id === payload.id);
  draft.packTypes[packTypeIndex] = payload;
};

const packTypeCreatedSuccess = (state, draft, payload) => {
  // debugger
  const packTypes = state.packTypes || [];
  draft.packTypes = [...packTypes, payload];

  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const packTypeDeleteSuccess = (state, draft, payload) => {
  const packTypes = state.packTypes || [];
  draft.packTypes = packTypes.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};




export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllPackTypes",
    stateName: "packTypes",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addPackType",
    stateName: "packType",
    async: true,
    isPaginated: false,
    successCb:packTypeCreatedSuccess
    
  }),
  ...bindReducers(ActionTypes, {
    action: "editPackType",
    stateName: "packType",
    async: true,
    isPaginated: false,
    successCb:packTypeEditedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deletePackType",
    stateName: "packType",
    async: true,
    isPaginated: false,
    successCb:packTypeDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),

});
