import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("categories", { metadata: undefined }, []),
};

//** categories
const categoryEditedSuccess = (state, draft, payload) => {
  //debugger
  const categories = state.categories || [];
  if(!payload?.parentCategoryId){
  var categoryIndex = categories.findIndex((u) => u.id === payload.id);
  draft.categories[categoryIndex] = payload;
  draft.categories[categoryIndex].subCategories=[...categories[categoryIndex]?.subCategories]

  }
  else{
    draft.changed=payload
  //   var categoryIndex = categories.find(c=>c.id===payload?.parentCategoryId)?.subCategories?.findIndex((u) => u.id === payload.id);
  // draft.categories.find(c=>c.id===payload?.parentCategoryId).subCategories[categoryIndex] = payload;
  }
  draft.openDialog = false;

};

const categoryCreatedSuccess = (state, draft, payload) => {
  const categories = state.categories || [];
  if(!payload?.parentCategoryId){
  draft.categories = [...categories, payload];
}
else{
  draft.categories.find(c=>c.id===payload?.parentCategoryId).subCategories=[...categories?.find(c=>c.id===payload?.parentCategoryId)?.subCategories,payload]
}
draft.openDialog = false;

};

const categoryDeleteSuccess = (state, draft, payload) => {
  if(!payload?.parentCategoryId){
  const categories = state.categories || [];
  draft.categories = categories.filter((a) => a.id != payload.id);
  }
  else{
    draft.changed=payload

  }
  draft.openDeleteDialog = false;
};

//** products
const productAddedSuccessfully = (state, draft, payload) => {
  draft.category.products = [payload, ...state.category.products];
};

const productEditedSuccessfully = (state, draft, payload) => {
  debugger;
  const products = state.category.products || [];
  var productIndex = products.findIndex((p) => p.id === payload.id);
  let packs = products[productIndex].packs;
  payload.packs = payload.packs.map((p) => ({
    ...p,
    isFeatured: packs.find((sp) => sp.id == p.id)?.isFeatured || false,
  }));
  draft.category.products[productIndex] = payload;
};

const productDeletedSuccessfully = (state, draft, payload) => {
  const products = state.category.products || [];
  var ptoductIndex = products.findIndex((p) => p.id === payload.id);
  draft.category.products.splice(ptoductIndex, 1);
};

// ** Featuerd Pack
const addedFeatuerdPackSuccessfully = (state, draft, payload) => {
  debugger;
  const { packIndex, productIndex } = state.packPosition;
  draft.category.products[productIndex].packs[packIndex].isFeatured =
    payload.data;
};
const changeTargetSuccessfully = (state, draft, payload) => {
  debugger;
  const { packIndex, productIndex } = state.packPosition;
  draft.category.products[productIndex].packs[packIndex].target = payload.data;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

export const reducer = createReducer(initialState, {
  //**Categories
  ...bindReducers(ActionTypes, {
    action: "getAllCategories",
    stateName: "categories",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getCategoryWithId",
    stateName: "category",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryCreatedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "editCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteCategory",
    stateName: "category",
    async: true,
    isPaginated: false,
    successCb: categoryDeleteSuccess,
  }),

  //** Products
  ...bindReducers(ActionTypes, {
    action: "addProduct",
    stateName: "categoryProduct",
    async: true,
    isPaginated: false,
    successCb: productAddedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "editProduct",
    stateName: "categoryProduct",
    async: true,
    isPaginated: false,
    successCb: productEditedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteProduct",
    stateName: "categoryProduct",
    async: true,
    isPaginated: false,
    successCb: productDeletedSuccessfully,
  }),
  //** Featuerd Pack
  ...bindReducers(ActionTypes, {
    action: "addFeatuerdPack",
    stateName: "featuerdPack",
    async: true,
    isPaginated: false,
    successCb: addedFeatuerdPackSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "changeTarget",
    stateName: "changeTarget",
    async: true,
    isPaginated: false,
    successCb: changeTargetSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "savePackPosition",
    stateName: "packPosition",
    async: false,
    isPaginated: false,
  }),

  //** Dialogs
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
