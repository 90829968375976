export default function (/**@type {ApisauceInstance} */ api) {
  const getShops = (paging) => api.get(`Shop`, paging);
  const getShopWithId = (id) => api.get(`Shop/${id}`);
  const addShop = (payload) => api.post(`Shop`, payload);
  const editShop = ({ payload, id }) => api.put(`Shop/${id}`, payload);
  const deleteShop = (id) => api.delete(`Shop/${id}`);

  //**consultants
  const getConsultants = (paging) =>
    api.get("user-management/consultant", paging);
  const getConsultantsWithShopId = (id) =>
    api.get(`user-management/consultants/${id}`);
  const addConsultant = (payload) =>
    api.post("user-management/consultant", payload);
    const addTargets = (payload) =>
    api.post("/Shop/targets", payload);
    const addTargetsList = (payload) =>
    api.post("/Shop/targets-list", payload);
    const getTargets = (id) =>
    api.get("/Shop/targets/"+id);
  const editConsultant = ({ payload, id }) =>
    api.put(`user-management/consultant/${id}`, payload);

  const deleteConsultant = (id) =>
    api.delete(`user-management/consultant/${id}`);

  return {
    shops: {
      getShops,
      getShopWithId,
      addShop,
      editShop,
      addTargets,
      getTargets,
      deleteShop,
      addTargetsList,
      //**consultants
      getConsultants,
      getConsultantsWithShopId,
      addConsultant,
      editConsultant,
      deleteConsultant,
    },
  };
}
