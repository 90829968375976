import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("unitsTypes", { metadata: undefined },{}),
};

const createRegionSuccess= (state, draft, payload) => {
  const regions = state.regions || [];
  draft.regions = [...regions, payload];
  draft.openDialog = false;
};
const deleteRegionSuccess= (state, draft, payload) => {
  draft.regions=state.regions.filter(r=>r.id!==payload.id);
};
const editRegionSuccess= (state, draft, payload) => {
  const index= state.regions.findIndex(r=>r.id===payload.id)
  if(index>=0){
    draft.regions[index]=payload;
  }
  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getUnitsTypes",
    stateName: "unitsTypes",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRegions",
    stateName: "regions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb:createRegionSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "editRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb:editRegionSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteRegion",
    stateName: "region",
    async: true,
    isPaginated: false,
    successCb:deleteRegionSuccess
  }),
 


  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
