export default function (/**@type {ApisauceInstance} */ api) {
  const getUnitsTypes = (paging) => api.get(`lookups/units-types`, paging);
  const getRegions = (paging) => {
    console.log(paging);
    return api.get(`region`, paging);
  };
  const createRegion = (payload) => api.post(`region`, payload);
  const editRegion = ({ id, payload }) => api.put(`region/${id}`, payload);
  const deleteRegion = (id) => api.delete(`region/${id}`);

  return {
    lookups: {
      getUnitsTypes,
      getRegions,
      createRegion,
      editRegion,
      deleteRegion,
    },
  };
}
