export default function (/**@type {ApisauceInstance} */ api) {
  const getTransactions = (payload) => api.get(`transactions`, payload);
  const getExportedTransactions= (payload) => api.get(`transactions/get-transaction-with-details`,payload);
  const getShopsTransactions= (payload) => api.get(`transactions/get-shop-transaction-pivot-categories`,payload);
  const getAllTransactions = () => api.get(`transactions/my-transactions-all`);
  const getTransactionWithId = (id) => api.get(`transactions/${id}`);

  return {
    transactions: {
      getTransactions,
      getAllTransactions,
      getShopsTransactions,
      getTransactionWithId,
      getExportedTransactions
    },
  };
}
