import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("transactions", { metadata: undefined }, []),
  ...defaultState("exportedTransactions", { metadata: undefined }, []),
  ...defaultState("shopsTransactions", { metadata: undefined }, []),
  ...defaultState(
    "transaction",
    { metadata: undefined },
    {
      invoices: [],
      purchases: [],
    }
  ),
  allTransactions: []
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getTransactions",
    stateName: "transactions",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getAllTransactions",
    stateName: "allTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getShopsTransactions",
    stateName: "shopsTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTransactionWithId",
    stateName: "transaction",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getExportedTransactions",
    stateName: "exportedTransactions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
