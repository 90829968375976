import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    // categories
    getGeneralInfo: { args: ["payload"], meta: { async: true } },
    getTransactions: { args: ["payload"], meta: { async: true } },
    getProducts: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/dashboard/",
  }
);
export const ActionTypes = Types;
export default Creators;
