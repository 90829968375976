import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllCities:{ args: ["payload"], meta: { async: true } },
    createCity:{ args: ["payload"], meta: { async: true } },
    editCity:{ args: ["payload"], meta: { async: true } },
    deleteCity:{ args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Cities/",
  }
);
export const ActionTypes = Types;
export default Creators;
