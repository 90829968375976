import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("units", { metadata: undefined }, []),
  ...defaultState(
    "unit",
    { metadata: undefined },
    {
      products: [],
    }
  ),
};


const unitEditedSuccess = (state, draft, payload) => {
  const units = state.units || [];
  var unitIndex = units.findIndex((u) => u.id === payload.id);
  draft.units[unitIndex] = payload;
};

const UnitCreatedSuccess = (state, draft, payload) => {
  const units = state.units || [];
  draft.units = [...units, payload];

  draft.openDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const unitDeleteSuccess = (state, draft, payload) => {
  const units = state.units || [];
  draft.units = units.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};



export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllUnits",
    stateName: "units",
    async: true,
    isPaginated: true
  }),
  ...bindReducers(ActionTypes, {
    action: "addUnit",
    stateName: "unit",
    async: true,
    isPaginated: false,
    successCb:UnitCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "editUnit",
    stateName: "unit",
    async: true,
    isPaginated: false,
    successCb:unitEditedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteUnit",
    stateName: "unit",
    async: true,
    isPaginated: false,
    successCb:unitDeleteSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
