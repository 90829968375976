import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";

const apiNamespace = "dashboard";

// categories

const getGeneralInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GENERAL_INFO,
});

const getTransactionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TRANSACTIONS,
});
const getProductsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PRODUCTS,
});
export default [
  getGeneralInfoLogic,
  getTransactionsLogic,
  getProductsLogic,
];
