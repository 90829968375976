import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "units";


const getAllUnitsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_UNITS,
});
const addUnitLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_UNIT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editUnitLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_UNIT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteUnitLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_UNIT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});



export default [
  getAllUnitsLogic,
  addUnitLogic,
  editUnitLogic,
  deleteUnitLogic,

];
