import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getShops: { args: ["payload"], meta: { async: true } },
    getShopWithId: { args: ["payload"], meta: { async: true } },
    addShop: { args: ["payload"], meta: { async: true } },
    editShop: { args: ["payload"], meta: { async: true } },
    deleteShop: { args: ["payload"], meta: { async: true } },
    addTargets: { args: ["payload"], meta: { async: true } },
    addTargetsList: { args: ["payload"], meta: { async: true } },
    getTargets: { args: ["payload"], meta: { async: true } },


    //consultants
    getConsultants: { args: ["payload"], meta: { async: true } },
    getConsultantsWithShopId: { args: ["payload"], meta: { async: true } },
    addConsultant: { args: ["payload"], meta: { async: true } },
    editConsultant: { args: ["payload"], meta: { async: true } },
    deleteConsultant: { args: ["payload"], meta: { async: true } },

    setShop: { args: ["payload"], meta: { async: false } },
    setTargetsList: { args: ["payload"], meta: { async: false } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Shops/",
  }
);
export const ActionTypes = Types;
export default Creators;
