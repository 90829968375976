export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllPackTypes = (paging) => api.get(`PackTypes/pack-types`,paging);

    const addPackType = (payload) => api.post(`PackTypes/add-pack-type`, payload);
  
    const editPackType = ({ payload, id }) =>api.put(`PackTypes/edit-pack-type/${id}`, payload);
  
    const deletePackType = (id) => api.delete(`PackTypes/delete-pack-type/${id}`);

    return {
      packTypes: {
        getAllPackTypes,
        addPackType,
        editPackType,
        deletePackType
      },
    };
  }
  