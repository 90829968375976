import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import layoutReducer from "./layout/reducer";
import navbarReducer from "./navbar/reducer";
// -------------------
import { authReducer } from "./auth/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "layout",
    "userManegment",
    "categories",
    "companyInfos",
    "packTypes",
    "units",
    "advertisements",
    "lookups",
    "companyInfos",
    "transactions",
    "governorates",
    "cities",
    "shops"
  ],
};

export default function createReducer(asyncReducers, history) {
  return persistReducer(
    rootPersistConfig,
    combineReducers({
      ...asyncReducers,
      router: connectRouter(history),
      app: require("./app/reducer").reducer,
      auth: authReducer,
      layout: layoutReducer,
      navbar: navbarReducer,
      userManegment: require("./userManagement/reducer").reducer,
      settings: require("./setting/reducer").reducer,
      categories: require("./categories/reducer").reducer,
      units: require("./unit/reducer").reducer,
      transactions: require("./transactions/reducer").reducer,
      packTypes: require("./packTypes/reducer").reducer,
      lookups: require("./lookups/reducer").reducer,
      companyInfos: require("./companyInfo/reducer").reducer,
      advertisements: require("./advertisements/reducer").reducer,
      governorates: require("./governorate/reducer").reducer,
      cities: require("./city/reducer").reducer,
      shops:require("./shop/reducer").reducer,
      dashboard:require("./dashboard/reducer").reducer,
    })
  );
}
