export default function (/**@type {ApisauceInstance} */ api) {
  const getAllGovernorate = (paging) => api.get(`Governorate`, paging);
  const getRegions = () => api.get(`Region`);
  const createGovernorate = (payload) => api.post(`Governorate`, payload);
  const editGovernorate = ({ id, payload }) => api.put(`Governorate/${id}`, payload);
  const deleteGovernorate = (id) => api.delete(`Governorate/${id}`);

  return {
    governorates: {
      getAllGovernorate,
      getRegions,
      createGovernorate,
      editGovernorate,
      deleteGovernorate,
    },
  };
}
