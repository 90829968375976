import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "packTypes";


const getAllPackTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_PACK_TYPES,
});
const addPackTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_PACK_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editPackTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_PACK_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deletePackTypeLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PACK_TYPE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});



export default [
  getAllPackTypesLogic,
  addPackTypeLogic,
  editPackTypeLogic,
  deletePackTypeLogic,

];
