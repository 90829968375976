import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import shopActions from "../shop/actions";

const apiNamespace = "userManegment";
const getCustomersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CUSTOMERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_USER,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getPermissionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_PERMISSIONS,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const createUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createUsersLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_USERS,
  showErrorMessage: true,
  successMessage: "users created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createAppUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_APP_USER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const createRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const getRolesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLES,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const getRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ROLE,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const deleteUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_USER,
  showErrorMessage: true,
  successMessage: "user Deleted successfully",
  showSuccessMessage: true,
});
const editUserLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_USER,
  showErrorMessage: true,
  showSuccessMessage: "User Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/users/list"));
  },
});
const editRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_ROLE,
  successCb: (dispatch) => {
    dispatch(push("/user-managment/roles/list"));
  },
});
const deleteRoleLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_ROLE,
  showErrorMessage: true,
  successMessage: "Role Deleted successfully",
  showSuccessMessage: true,
});

const getRegionManagersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REGION_MANAGERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getUnassignedRegionManagersLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_UNASSIGNED_REGION_MANAGERS,
  showErrorMessage: true,
  showSuccessMessage: false,
});
const getRegionManagerLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REGION_MANAGER,
  showErrorMessage: false,
  showSuccessMessage: false,
});
const createRegionManagerLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_REGION_MANAGER,
  showErrorMessage: true,
  successMessage: "user created successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/region-managers"));
  },
});
const editRegionManagerLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_REGION_MANAGER,
  showErrorMessage: true,
  showSuccessMessage: "User Edited Successfully",
  showSuccessMessage: true,
  successCb: (dispatch) => {
    dispatch(push("/region-managers"));
  },
});
const deleteRegionManagerLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REGION_MANAGER,
  showErrorMessage: true,
  successMessage: "user Deleted successfully",
  showSuccessMessage: true,
});
const getShopConsultantsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SHOP_CONSULTANTS,
  showErrorMessage: true,
});



export default [
  getUsersLogic,
  createUserLogic,
  createUsersLogic,
  createAppUserLogic,
  getRolesLogic,
  getUserLogic,
  deleteUserLogic,
  editUserLogic,
  createRoleLogic,
  getPermissionsLogic,
  deleteRoleLogic,
  editRoleLogic,
  getRoleLogic,
  getCustomersLogic,
  getRegionManagersLogic,
  getRegionManagerLogic,
  createRegionManagerLogic,
  editRegionManagerLogic,
  deleteRegionManagerLogic,
  getUnassignedRegionManagersLogic,
  getShopConsultantsLogic

];
