import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("governorates", { metadata: undefined },[]),
};

const editGovernorateSuccess= (state, draft, payload) => {
  const index= state.governorates.findIndex(r=>r.id===payload.id)
  if(index>=0){
    draft.governorates[index]=payload;
  }
  draft.openDialog = false;
};
const governoratesCreatedSuccess = (state, draft, payload) => {
  const governorates = state.governorates || [];
  draft.governorates = [...governorates, payload];
  draft.openDialog = false;
};

const governoratesDeleteSuccess = (state, draft, payload) => {
  const governorates = state.governorates || [];
  draft.governorates = governorates.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllGovernorate",
    stateName: "governorates",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getRegions",
    stateName: "regions",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb:governoratesCreatedSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "editGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb:editGovernorateSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteGovernorate",
    stateName: "governorate",
    async: true,
    isPaginated: false,
    successCb:governoratesDeleteSuccess
  }),
 


  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
