export default function (/**@type {ApisauceInstance} */ api) {
    const getGeneralInfo = () => api.get(`dashboard/general-info`);
    const getTransactions = () => api.get(`dashboard/transactions`);
    const getProducts = () => api.get(`dashboard/most-sell-products`);
    return {
      dashboard: {
        getGeneralInfo,
        getTransactions,
        getProducts,
      },
    };
  }