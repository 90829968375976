import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "shops";

const getShopsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SHOPS,
});
const getShopWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_SHOP_WITH_ID,
});

const addTargetsLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_TARGETS,
});
const addTargetsListLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_TARGETS_LIST,
});
const getTargetsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_TARGETS,
});

const addShopLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_SHOP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editShopLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_SHOP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteShopLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_SHOP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

//consultants

const getConsultantsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CONSULTANTS,
  showErrorMessage: true,
});
const getConsultantsWithShopIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CONSULTANTS_WITH_SHOP_ID,
  showErrorMessage: true,
  showSuccessMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
});
const addConslutantLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_CONSULTANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editConslutantLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CONSULTANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteConslutantLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CONSULTANT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});
export default [
  getShopsLogic,
  getShopWithIdLogic,
  addTargetsListLogic,
  addShopLogic,
  addTargetsLogic,
  getTargetsLogic,
  editShopLogic,
  deleteShopLogic,
  //consultants
  getConsultantsLogic,
  getConsultantsWithShopIdLogic,
  addConslutantLogic,
  editConslutantLogic,
  deleteConslutantLogic
];
