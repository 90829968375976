import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("shops", { metadata: undefined }, []),
  ...defaultState("shop", { metadata: undefined }, { consultants: [] }),
};
const addTargetsSuccess=(state, draft, payload) => {
  draft.openDialog=false;
};
const addTargetsListSuccess=(state, draft, payload) => {
  draft.targetsList=payload?.data;
};

const consultantAddedSuccessfully = (state, draft, payload) => {
  let consultants = state.shop.consultants || [];
  draft.shop = { ...state.shop, consultants: [...consultants, payload] };
  draft.openDialog = false;

};
const consultantEditedSuccessfully = (state, draft, payload) => {
  let consultants = [...state.shop.consultants];
  let index = consultants.findIndex((c) => c.id == payload.id);
  consultants[index] = payload;
  draft.shop = { ...state.shop, consultants };
  draft.openDialog = false;
};
const consultantDeletedSuccessfully = (state, draft, payload) => {
  let consultants = [...state.shop.consultants];
  consultants = consultants.filter((c) => c.id != payload.id);
  draft.shop = { ...state.shop, consultants };
};

const shopEditedSuccessfully = (state, draft, payload) => {
  draft.shop = { ...state.shop, ...payload };
};
const shopDeletedSuccessfully = (state, draft, payload) => {
  let shops = [...state.shops];
  shops = shops.filter((s) => s.id != payload.id);
  draft.shops = shops;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDeleteDialog = false;
  draft.openDialog = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getShops",
    stateName: "shops",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getShopWithId",
    stateName: "shop",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addShop",
    stateName: "shop",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editShop",
    stateName: "shop",
    async: true,
    isPaginated: false,
    successCb: shopEditedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteShop",
    stateName: "shop",
    async: true,
    isPaginated: false,
    successCb: shopDeletedSuccessfully,
  }),

  ...bindReducers(ActionTypes, {
    action: "setShop",
    stateName: "shop",
    async: false,
    isPaginated: false,
  }),

  // ** consultants
  ...bindReducers(ActionTypes, {
    action: "getConsultants",
    stateName: "consultants",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "addTargets",
    stateName: "targets",
    async: true,
    isPaginated: false,
    successCb:addTargetsSuccess
  }),
  ...bindReducers(ActionTypes, {
    action: "addTargetsList",
    stateName: "targetsList",
    async: true,
    isPaginated: false,
    successCb:addTargetsListSuccess
   
  }),
  ...bindReducers(ActionTypes, {
    action: "setTargetsList",
    stateName: "targetsList",
    async: false,
    isPaginated: false,
    
   
  }),
  ...bindReducers(ActionTypes, {
    action: "getTargets",
    stateName: "shopTargets",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getConsultantsWithShopId",
    stateName: "shopConsultants",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addConsultant",
    stateName: "shopConsultants",
    async: true,
    isPaginated: false,
    successCb: consultantAddedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "editConsultant",
    stateName: "shopConsultants",
    async: true,
    isPaginated: false,
    successCb: consultantEditedSuccessfully,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteConsultant",
    stateName: "shopConsultants",
    async: true,
    isPaginated: false,
    successCb: consultantDeletedSuccessfully,
  }),

  // ** delete dialog
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
