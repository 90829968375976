export default function (/**@type {ApisauceInstance} */ api) {
  const getAdvertisement = () => api.get(`advertisement/`);
  const addAdvertisement = (payload) =>
    api.post(`advertisement/add-advertisement`, payload);
  const editAdvertisement = ({ payload, id }) =>
    api.put(`advertisement/edit-advertisement/${id}`, payload);
  const deleteAdvertisement = (id) =>
    api.delete(`advertisement/delete-advertisement/${id}`);

  return {
    advertisements: {
      getAdvertisement,
      addAdvertisement,
      editAdvertisement,
      deleteAdvertisement,
    },
  };
}
