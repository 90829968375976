import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllPackTypes: { args: ["payload"], meta: { async: true } },
    addPackType: { args: ["payload"], meta: { async: true } },
    editPackType: { args: ["payload"], meta: { async: true } },
    deletePackType: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/packTypes/",
  }
);
export const ActionTypes = Types;
export default Creators;
