export default function (/**@type {ApisauceInstance} */ api) {
  // categories

  const getAllCategories = (paging) => api.get(`categories/categories`, paging);
  const getCategoryWithId = (id) => api.get(`categories/category/${id}`);
  const addCategory = (payload) => api.post(`categories/add-category`, payload);

  const editCategory = ({ payload, id }) =>
    api.put(`categories/edit-category/${id}`, payload);

  const deleteCategory = (id) => api.delete(`categories/delete-category/${id}`);

  // products
  const addProduct = ({ payload }) => api.post(`products/add-product`, payload);

  const editProduct = ({ payload, id }) =>
    api.put(`products/edit-product/${id}`, payload);

  const deleteProduct = (id) => api.delete(`products/delete-product/${id}`);

  //Featuerd Pack
  const addFeatuerdPack = ({ payload }) =>
    api.post(`products/add-featured-pack`, payload);
  const changeTarget = ({ payload }) =>
    api.post(`products/pack-target`, payload);
  return {
    categories: {
      // categories
      getAllCategories,
      getCategoryWithId,
      addCategory,
      editCategory,
      deleteCategory,
      // products
      addProduct,
      editProduct,
      deleteProduct,
      //Featuerd Pack
      addFeatuerdPack,
      changeTarget,
    },
  };
}
