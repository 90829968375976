import logic from "src/utils/genLogic";
import actions,{ ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "lookups";

const getUnitsTypesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_UNITS_TYPES,
});
const getRegionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REGIONS,
});

const createRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const editRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteRegionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_REGION,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});



export default [
  getUnitsTypesLogic,
  getRegionsLogic,
  createRegionLogic,
  editRegionLogic,
  deleteRegionLogic,
];
