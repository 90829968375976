import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { push } from "connected-react-router";
import { customSweetAlert } from "src/components/shared/customSweetAlert";

const apiNamespace = "companyInfos";

const getCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_COMPANY_INFO,
});
const saveCompanyInfoLogic = logic(apiNamespace, {
  actionName: ActionTypes.SAVE_COMPANY_INFO,
  successCb:()=>{
    customSweetAlert(
      {
        title: "تم حفظ بنجاح",
        text:"اضغط موافق للمتابعة",
        icon:"success",
        confirmButtonClasses: "btn btn-primary",
        confirmButtonText: "موافق"
      });
  },
  failCb:()=>{
    customSweetAlert(
      {
        title: "حدث خطأ اثناء الحفظ و المحاولة مرة اخرى",
        text:"و اضغط موافق للمتابعة",
        icon:"danager",
        confirmButtonClasses: "btn btn-primary",
        confirmButtonText: "موافق"
      });
  }
});



export default [
  getCompanyInfoLogic,
  saveCompanyInfoLogic

];
