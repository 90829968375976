import apisauce from "apisauce";
import config from "../configs";

import authApi from "./auth";
import usersApi from "./userManagement";
import settingsApi from "./setting";

//---------------------
import transactionsApi from "./transactions";
import categoriesApi from "./categories";
import packTypesApi from "./packTypes";
import unitApi from "./unit";
import companyInfoApi from "./companyInfo";
import lookupsApi from "./lookups";
import advertisementApi from "./advertisement";
import governorateApi from "./governorate";
import shopApi from "./shop";
import cityApi from "./city";
import dashboardApi from "./dashboard";

// const apiURI = config.useLocalApi ? config.devApiRoot : config.apiRoot;
const apiURI = process.env.REACT_APP_BASE_API_URL;
const create = (baseURL = apiURI) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 1200000, //30 sec
  });
  //add access token on each request

  api.addAsyncResponseTransform((response) => async () => {
    if (!response.ok) {
      const customeError = {
        errors: response.data.errors || [
          { errorMessage: response.data.errorMessage },
        ],
        // title: response.data.title,
        errorMessage: response.data.errorMessage,
        // details: response.data.details,
        // data: response.data.result,
        status: response.status,
        code: response.data.errorCode,
      };
      response.data = customeError;
    }
  });

  api.addAsyncRequestTransform((request) => async () => {
    const token = localStorage.getItem("jwtToken");
    const culture = localStorage.getItem("culture");
    const cultureCode = localStorage.getItem("cultureCode");
    const userIp = localStorage.getItem("userIp");

    request.headers["culture"] = culture || "en";
    request.headers["cultureCode"] = cultureCode || "en-US";
    request.headers["userIp"] = userIp || "";

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete request.headers["Authorization"];
    }
  });

  const auth = authApi(api);
  const users = usersApi(api);
  const settings = settingsApi(api);
  // --------------------
  const categories = categoriesApi(api);
  const packTypes = packTypesApi(api);
  const units = unitApi(api);
  const transactions = transactionsApi(api);
  const companyInfos = companyInfoApi(api);
  const lookups = lookupsApi(api);
  const advertisements = advertisementApi(api);
  const governorates = governorateApi(api);
  const cities = cityApi(api);
  const shops=shopApi(api);
  const dashboard=dashboardApi(api);

  // const transactions = transactionsApi(api);
  return {
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    ...auth,
    ...users,
    ...settings,
    ...categories,
    ...packTypes,
    ...units,
    ...companyInfos,
    ...lookups,
    ...advertisements,
    ...transactions,
    ...governorates,
    ...shops,
    ...cities,
    ...dashboard,
  };
};

export default { create };
