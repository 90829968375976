import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/utils/genState";
import createReducer from "src/utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("advertisement", { metadata: undefined }),
};

const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};

const resetDialog = (state, draft, payload) => {
  draft.openDeleteDialog = false;
};
const advertisememntDeleteedSuccessfully = (state, draft, payload) => {
  delete draft.advertisement;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "addAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "editAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteAdvertisement",
    stateName: "advertisement",
    async: true,
    isPaginated: false,
    successCb: advertisememntDeleteedSuccessfully,
  }),

  // ** delete dialog
  ...bindReducers(ActionTypes, {
    action: "setAdvertisement",
    stateName: "advertisement",
    async: false,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
});
