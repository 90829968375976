import logic from "src/utils/genLogic";
import { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

import { customSweetAlert } from "src/components/shared/customSweetAlert";

const apiNamespace = "categories";

// categories

const getAllCategoriesLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_CATEGORIES,
});
const getCategoryWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_CATEGORY_WITH_ID,
});
const addCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_CATEGORY,
  successCb:()=>{
    customSweetAlert(
      {
        title: "تم حفظ بنجاح",
        text:"اضغط موافق للمتابعة",
        icon:"success",
        confirmButtonClasses: "btn btn-primary",
        confirmButtonText: "موافق"
      });
  }
});

const editCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_CATEGORY,
  successCb:()=>{
    customSweetAlert(
      {
        title: "تم حفظ بنجاح",
        text:"اضغط موافق للمتابعة",
        icon:"success",
        confirmButtonClasses: "btn btn-primary",
        confirmButtonText: "موافق"
      });
  }
});

const deleteCategoryLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_CATEGORY,
});

// products

const addProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const editProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});

const deleteProductLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_PRODUCT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

//Featuerd Pack
const addFeatuerdPackLogic = logic(apiNamespace, {
  actionName: ActionTypes.ADD_FEATUERD_PACK,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
});
const changeTargetLogic = logic(apiNamespace, {
  actionName: ActionTypes.CHANGE_TARGET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="target_changed_successfully" />,
});

export default [
  // categories
  getAllCategoriesLogic,
  getCategoryWithIdLogic,
  addCategoryLogic,
  editCategoryLogic,
  deleteCategoryLogic,
  // products
  addProductLogic,
  editProductLogic,
  deleteProductLogic,
  //Featuerd Pack
  addFeatuerdPackLogic,
  changeTargetLogic,
];
