export default function (/**@type {ApisauceInstance} */ api) {
  const getCompanyInfo = () => api.get(`CompanyInfo/companyInfo`);
  const saveCompanyInfo = (payload) => api.post(`CompanyInfo/save-company-info`, payload);
  return {
    companyInfos: {
      saveCompanyInfo,
      getCompanyInfo
    },
  };
}
