import logic from "src/utils/genLogic";
import actions, { ActionTypes } from "./actions";
import { Trans } from "@lingui/react";

const apiNamespace = "governorates";

const getAllGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_GOVERNORATE,
});
const getRegionsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_REGIONS,
});
const createGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GOVERNORATE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const editGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.EDIT_GOVERNORATE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
});
const deleteGovernorateLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_GOVERNORATE,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

export default [
  getAllGovernorateLogic,
  getRegionsLogic,
  createGovernorateLogic,
  editGovernorateLogic,
  deleteGovernorateLogic,
];
