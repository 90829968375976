export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllUnits = (paging) => api.get(`Units/units`,paging);

    const addUnit = (payload ) =>api.post(`Units/add-unit`, payload);
  
    const editUnit = ({ payload, id }) =>api.put(`Units/edit-unit/${id}`, payload);
  
    const deleteUnit = (id) => api.delete(`Units/delete-unit/${id}`);

    return {
      units: {
        getAllUnits,
        addUnit,
        editUnit,
        deleteUnit
      },
    };
  }
  