import appLogic from "./app/logic";
import authLogic from "./auth/logic";
import usersLogic from "./userManagement/logic";
import settingLogic from "./setting/logic";
//---------------------------
import categoriesLogic from "./categories/logic";
import unitsLogic from "./unit/logic";
import packTypesLogic from "./packTypes/logic";
import companyInfoLogic from "./companyInfo/logic";
import lookupsLogic from "./lookups/logic";
import advertisementsLogic from "./advertisements/logic";
import transactionsLogic from "./transactions/logic";
import governoratesLogic from "./governorate/logic";
import shopsLogic  from "./shop/logic";
import citiesLogic from "./city/logic";
import dashboardLogic from "./dashboard/logic";

export default [
  ...appLogic,
  ...authLogic,
  ...usersLogic,
  ...settingLogic,
  ...categoriesLogic,
  ...unitsLogic,
  ...lookupsLogic,
  ...packTypesLogic,
  ...companyInfoLogic,
  ...advertisementsLogic,
  ...transactionsLogic,
  ...governoratesLogic,
  ...citiesLogic,
  ...shopsLogic,
  ...dashboardLogic,
];
