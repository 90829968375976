import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getUnitsTypes:{ args: ["payload"], meta: { async: true } },
    getRegions:{ args: ["payload"], meta: { async: true } },
    createRegion:{ args: ["payload"], meta: { async: true } },
    editRegion:{ args: ["payload"], meta: { async: true } },
    deleteRegion:{ args: ["payload"], meta: { async: true } },

    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/lookups/",
  }
);
export const ActionTypes = Types;
export default Creators;
